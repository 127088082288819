import AzkSelect from "_azkatech/azkSelect";
import { EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { TravelRequest, TravelRequestLine, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { CreateTravelRequest, UpdateTravelRequest, GetTravelRequestById, MODEL_NAME, DeleteTravelRequest } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { ID, isNotEmpty } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    passport_number: Yup.string().required('Passport number is required'),
    name_in_passport: Yup.string().required('Name is required'),
    passport_expiry: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Passport expiry date is required')
        .min(new Date(), 'Date from must be after today'),
    line_ids: Yup.array().of(
        Yup.object().shape({
            departure_date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Departure date is required')
                .min(new Date(), 'Date from must be after today'),
            departure_city: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Departure city from is required'),
            arrival_city: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Arrival city from is required')
            .test('rquired', 'Can not match departure city', function (value) {
                let departure_city = this.parent['departure_city']
                return departure_city !== value ;
            }),
        })
    )
})

let is_submitting_form = false
let submit_validation_transaction = false
const TransactionAPPform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<TravelRequest>>(null);
    const [travelRequestTypeDomain, setTravelRequestTypeDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [show_employee_details, setShow_employee_details] = useState<boolean>(true);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();

    const [formFields, setFormFields] = useState<TravelRequestLine[]>();

    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const handleEmployeeChange = async function (employeeId): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        passport_expiry: moment(tempEmployee?.passport_expiry).format(AZKHelper.FRONTEND_DATE_FORMAT),
                        mobile_number: tempEmployee?.mobile_phone,
                        name_in_passport: tempEmployee?.name_in_passport,
                        passport_number: tempEmployee?.passport_number,
                        date_of_birth: moment(tempEmployee?.birthday).format(AZKHelper.FRONTEND_DATE_FORMAT),
                        // ---------------------------------------------------
                        line_ids: [
                            {
                                departure_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                                departure_city: '',
                                arrival_city: '',
                                need_hotel: true,
                                need_visa: false,
                                purpose: '',
                                passport_visa_info: '',
                                id: 0
                            },
                            {
                                departure_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                                departure_city: '',
                                arrival_city: '',
                                need_hotel: true,
                                need_visa: false,
                                purpose: '',
                                passport_visa_info: '',
                                id: 0
                            },]
                    })
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                mobile_number: undefined,
                date_of_birth: undefined,
                passport_number: undefined,
                passport_expiry: undefined,
                smoking: undefined,
                has_credit_card: undefined,
                travel_insurance: false,
                additional_remarks: undefined,
                save_personal_data: true,
                line_ids: [
                    {
                        departure_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        departure_city: '',
                        arrival_city: '',
                        need_hotel: true,
                        need_visa: false,
                        purpose: '',
                        passport_visa_info: '',
                        id: 0
                    },
                    {
                        departure_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        departure_city: '',
                        arrival_city: '',
                        need_hotel: true,
                        need_visa: false,
                        purpose: '',
                        passport_visa_info: '',
                        id: 0
                    },]
            })
        }
        return true
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/app/self-service/travel-request/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "center",
                        // title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        // toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/app/self-service/travel-request/`, { state: {}, replace: true });
                Swal.fire({
                    position: "center",
                    // title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    // toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        checkPermission('create');
        setCanEdit(true)
        document.body.classList.remove('page-loading')
        handelUserAllowedEmployes()
    }, [id])

    const handelUserAllowedEmployes = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let domain = ``
                        if (response.allwoed_travel_request && response.allwoed_travel_request !== null) {
                            domain = domain.concat(`'|', ('id', '=', ${response.id}), ('id', 'in', [${response.allwoed_travel_request}])`)
                        } else {
                            domain = domain.concat(`('id', '=', ${response.id})`)
                        }
                        setEmployeeDomain(domain)
                    }
                })
            }
        }
    }

    const cancel = () => {
        navigate(`/app/self-service/travel-request/`, { state: {}, replace: true });
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/app/self-service/travel-request/`, { state: {}, replace: true })
        Swal.fire({
            position: "center",
            // title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            // toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const validateFields = async () => {
        let canSave = true
        const fieldsValue: any[] = []
        let fields: TravelRequestLine[] = []
        if (formFields) fields = formFields
        if (!formFields) return canSave
        // replace the element object
        // await fields.map((element) => {
        //     if (!element.valid && element.required) {
        //         canSave = false
        //     } else if (element.value) {
        //         fieldsValue.push(
        //             { 'key': element.name, 'field_id': element.id, 'value': element.value })
        //     }
        // });
        return { canSave, fieldsValue }
    }
    const onSubmitHandler = async (values: TravelRequest, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid && is_submitting_form) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id)) {
                await UpdateTravelRequest(values).then(data => {
                    if (id)
                        if (submit_validation_transaction) {
                            axios.put(`${API_URL}/${MODEL_NAME}/${data?.id}/request_validation`, '{}').then(data => {
                                handleResultOfsubmit(parseInt(id), true)
                            })
                        } else {
                            handleResultOfsubmit(undefined, false)
                        }
                })
            } else {
                await CreateTravelRequest(values).then(data => {
                    if (submit_validation_transaction)
                        axios.put(`${API_URL}/${MODEL_NAME}/${data?.id}/request_validation`, '{}').then(data => {
                            handleResultOfsubmit(undefined, false)
                        })
                    else handleResultOfsubmit(undefined, false)
                })
            }
            // }
            // })

        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'support', label: 'Support' })
        options.push({ value: 'training', label: 'Training' })
        options.push({ value: 'other', label: 'Other' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteTravelRequest(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    return (
        <>
            <Row className="m-0 my-2 mobile-app-container">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <>
                                            <Row className="card">
                                                <h6 className="d-flex justify-content-between">
                                                    <span>Travel details:</span> <i className={`rounded btn btn-sm btn-icon btn-dark-light btn-dark fa-solid rounded ${(show_employee_details ? "fa-chevron-up" : 'fa-chevron-down')}`}
                                                     onClick={()=>{
                                                        setShow_employee_details(!show_employee_details)
                                                     }}></i>
                                                </h6>
                                                <fieldset disabled={!canEdit || readOnly} className="p-0">
                                                    {show_employee_details && <>
                                                    <Row className="m-0 my-2">
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Employee Name
                                                                </Form.Label>
                                                                {employeeDomain && employeeDomain !== '' &&
                                                                    <AzkSelect
                                                                        compID="azk_temp"
                                                                        modelName="hr.employee"
                                                                        valueField="id"
                                                                        titleField="display_name_fr"
                                                                        dataDomain={employeeDomain}
                                                                        allowEmpty={true}
                                                                        fieldName='employee_id'
                                                                        onChange={(value) => {
                                                                            setFieldValue('employee_id', parseInt(value))
                                                                            handleEmployeeChange(value)
                                                                        }}
                                                                        defaultValue={values.employee_id}
                                                                        disabled={!canEdit}
                                                                        searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                                }
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Name as in passport
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className='p-1 rounded-0'
                                                                    type="text"
                                                                    name="name_in_passport"
                                                                    id="name_in_passport"
                                                                    onChange={(val) => {
                                                                        setFieldValue('name_in_passport', val.target.value)
                                                                    }}
                                                                    value={values.name_in_passport}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='name_in_passport' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Passport Number
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className='p-1 rounded-0'
                                                                    name="passport_number"
                                                                    id="passport_number"
                                                                    onChange={(val) => {
                                                                        setFieldValue('passport_number', val.target.value)
                                                                    }}
                                                                    value={values.passport_number}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='passport_number' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Passport Expiry
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className='p-1 rounded-0'
                                                                    type='date'
                                                                    name="passport_expiry"
                                                                    id="passport_expiry"
                                                                    onChange={(value) => {
                                                                        setFieldValue('passport_expiry', moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                                                    }}
                                                                    disabled={!canEdit}
                                                                    value={moment(values.passport_expiry).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='passport_expiry' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Mobile Number
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className='p-1 rounded-0'
                                                                    type="text"
                                                                    name="mobile_number"
                                                                    id="mobile_number"
                                                                    onChange={(val) => {
                                                                        setFieldValue('mobile_number', val.target.value)
                                                                    }}
                                                                    value={values.mobile_number}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='mobile_number' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                    Date Of Birth
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className='p-1 rounded-0'
                                                                    type='date'
                                                                    name="date_of_birth"
                                                                    id="date_of_birth"
                                                                    onChange={(value) => {
                                                                        setFieldValue('date_of_birth', moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                                                    }}
                                                                    disabled={!canEdit}
                                                                    value={moment(values.date_of_birth).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='date_of_birth' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                </Form.Label>
                                                                <Row>
                                                                    <Col lg="12" md="6" sm="12" xs="12">
                                                                        <Form.Check
                                                                            label="Smoking Hotel Room"
                                                                            value="true"
                                                                            id={"smoking"}
                                                                            name={"smoking"}
                                                                            onChange={(value) => {
                                                                                values.smoking = value.target.checked
                                                                                setFieldValue('smoking', value.target.checked)
                                                                            }}
                                                                            defaultChecked={values.smoking}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='smoking' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>

                                                                </Form.Label>
                                                                <Row>
                                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                                        <Form.Check
                                                                            label="Has Corporate credit card"
                                                                            id={"has_credit_card"}
                                                                            name={"has_credit_card"}
                                                                            onChange={(value) => {
                                                                                values.has_credit_card = value.target.checked
                                                                                setFieldValue('has_credit_card', value.target.checked)
                                                                            }}
                                                                            defaultChecked={values.has_credit_card}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='has_credit_card' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        {/* <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                </Form.Label>
                                                                <Row>
                                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                                        <Form.Check
                                                                            label="Has Yearly travel Insurance"
                                                                            id={"travel_insurance"}
                                                                            name={"travel_insurance"}
                                                                            onChange={(value) => {
                                                                                values.travel_insurance = value.target.checked
                                                                                setFieldValue('travel_insurance', value.target.checked)
                                                                            }}
                                                                            defaultChecked={values.travel_insurance}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='travel_insurance' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}

                                                    </Row>
                                                    </>}
                                                    <Row className="m-0 my-2">
                                                        <FieldArray name="line_ids">
                                                            {({ push, remove }) => (
                                                                <>
                                                                    {values.line_ids.map((line, index) => (
                                                                        <>
                                                                            <Row className="m-0 my-2 py-4" style={{ backgroundColor: '#F6F6F6' }}>
                                                                                <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-between">
                                                                                    <h6>
                                                                                        Flight {index + 1} :
                                                                                    </h6>
                                                                                    {(index > 0 || values.line_ids.length > 1) &&
                                                                                        < button
                                                                                            name={`line_ids[${index}].min` + 'delete_line'}
                                                                                            title="delete"
                                                                                            type="reset"
                                                                                            onClick={() => { remove(index) }}
                                                                                            className='p-0 btn btn-outline-dark bg-transparent rounded'
                                                                                        >
                                                                                            <i className="bi bi-trash" aria-hidden="true"></i>
                                                                                        </button>
                                                                                    }
                                                                                </Col>
                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            Departure Date
                                                                                        </Form.Label>
                                                                                        <Form.Control
                                                                                            className='p-1 col-12 rounded-0'
                                                                                            type="date"
                                                                                            id={`line_ids[${index}].departure_date`}
                                                                                            name={`line_ids[${index}].departure_date`}
                                                                                            onChange={(value) => {
                                                                                                line.departure_date = moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                                                values.line_ids[index].departure_date = moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            // min={}
                                                                                            value={moment(values.line_ids[index].departure_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                        />
                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].departure_date`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            Departure City
                                                                                        </Form.Label>
                                                                                        <AzkSelect
                                                                                            compID={"az_departure_city_select" + index}
                                                                                            modelName="res.city"
                                                                                            valueField="id"
                                                                                            titleField="name"
                                                                                            dataDomain=''
                                                                                            isMultiple={false}
                                                                                            allowEmpty={true}
                                                                                            fieldName=''
                                                                                            disabled={!canEdit}
                                                                                            onChange={(value) => {
                                                                                                line.departure_city = parseInt(value)
                                                                                                values.line_ids[index].departure_city = parseInt(value)
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            defaultValue={values.line_ids[index].departure_city} />
                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].departure_city`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            Arrival City
                                                                                        </Form.Label>
                                                                                        <AzkSelect
                                                                                            compID={"az_arrival_city_select" + index}
                                                                                            modelName="res.city"
                                                                                            valueField="id"
                                                                                            titleField="name"
                                                                                            dataDomain=''
                                                                                            isMultiple={false}
                                                                                            allowEmpty={true}
                                                                                            fieldName=''
                                                                                            disabled={!canEdit}
                                                                                            onChange={(value) => {
                                                                                                line.arrival_city = parseInt(value)
                                                                                                values.line_ids[index].arrival_city = parseInt(value)
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            defaultValue={values.line_ids[index].arrival_city} />
                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].arrival_city`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            Hotel
                                                                                        </Form.Label>
                                                                                        <Row>
                                                                                            <Col lg="4" md="6" sm="6" xs="6">
                                                                                                <Form.Check
                                                                                                    label="Yes"
                                                                                                    radioGroup={"need_hotel" + index}
                                                                                                    id={"need_hotel_yes" + index}
                                                                                                    name={"need_hotel_yes" + index}
                                                                                                    onClick={(value) => {
                                                                                                        line.need_hotel = true
                                                                                                        values.line_ids[index].need_hotel = true
                                                                                                        validateForm()
                                                                                                    }
                                                                                                    }
                                                                                                    defaultChecked={values.line_ids[index].need_hotel === true}
                                                                                                    checked={values.line_ids[index].need_hotel === true}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col lg="4" md="6" sm="6" xs="6">
                                                                                                <Form.Check
                                                                                                    radioGroup={"need_hotel" + index}
                                                                                                    label="No"
                                                                                                    id={"need_hotel_no" + index}
                                                                                                    name={"need_hotel_no" + index}
                                                                                                    onClick={(value) => {
                                                                                                        line.need_hotel = false
                                                                                                        values.line_ids[index].need_hotel = false
                                                                                                        validateForm()
                                                                                                    }}
                                                                                                    defaultChecked={values.line_ids[index].need_hotel !== true}
                                                                                                    checked={values.line_ids[index].need_hotel !== true}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].need_hotel`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            NEED FOR VISA
                                                                                        </Form.Label>
                                                                                        <Row>
                                                                                            <Col lg="4" md="6" sm="6" xs="6">
                                                                                                <Form.Check
                                                                                                    label="Yes"
                                                                                                    radioGroup={"need_visa" + index}
                                                                                                    id={"need_visa_yes" + index}
                                                                                                    name={"need_visa_yes" + index}
                                                                                                    onClick={(value) => {
                                                                                                        line.need_visa = true
                                                                                                        values.line_ids[index].need_visa = true
                                                                                                        validateForm()
                                                                                                    }
                                                                                                    }
                                                                                                    defaultChecked={values.line_ids[index].need_visa === true}
                                                                                                    checked={values.line_ids[index].need_visa === true}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col lg="4" md="6" sm="6" xs="6">
                                                                                                <Form.Check
                                                                                                    radioGroup={"need_visa" + index}
                                                                                                    label="No"
                                                                                                    id={"need_visa_no" + index}
                                                                                                    name={"need_visa_no" + index}
                                                                                                    onClick={(value) => {
                                                                                                        line.need_visa = false
                                                                                                        values.line_ids[index].need_visa = false
                                                                                                        validateForm()
                                                                                                    }}
                                                                                                    defaultChecked={values.line_ids[index].need_visa !== true}
                                                                                                    checked={values.line_ids[index].need_visa !== true}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].need_visa`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>


                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            If not, specify available passport/visa with validity date & number of entries.
                                                                                        </Form.Label>
                                                                                        <Form.Control
                                                                                            className='p-1 rounded-0'
                                                                                            id={"passport_visa_info" + index}
                                                                                            type="text"
                                                                                            name={"passport_visa_info" + index}
                                                                                            disabled={!canEdit}
                                                                                            onChange={(value) => {
                                                                                                line.passport_visa_info = value.target.value
                                                                                                values.line_ids[index].passport_visa_info = value.target.value
                                                                                            }}
                                                                                            defaultValue={values.line_ids[index].passport_visa_info}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].passport_visa_info`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>


                                                                                <Col lg="4" md="12" sm="12" xs="12">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Label className='required'>
                                                                                            TRIP PURPOSE
                                                                                        </Form.Label>
                                                                                        <AsyncPaginate className='rounded-0 col-12'
                                                                                            placeholder="Select an option"
                                                                                            loadOptions={loadTypeOptions}
                                                                                            onChange={(value) => {
                                                                                                line.purpose = value?.value
                                                                                                values.line_ids[index].purpose = value?.value
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            defaultValue={{ value: values.line_ids[index].purpose, label: values.line_ids[index].purpose }}
                                                                                            value={{ value: values.line_ids[index].purpose, label: values.line_ids[index].purpose }}
                                                                                            id={`line_ids[${index}].purpose`} name={`line_ids[${index}].purpose`}
                                                                                        />
                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name={`line_ids[${index}].purpose`} component="div" />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                {/* <Col lg="4" md="12" sm="12" xs="12">
                                                                                        <Form.Group className='mb-2'>
                                                                                            <Form.Label>
                                                                                                ADDITIONAL REMARKS
                                                                                            </Form.Label>
                                                                                            <Form.Control
                                                                                                className='p-1 rounded-0'
                                                                                                id={"additional_remarks" + index}
                                                                                                type="text"
                                                                                                name={"additional_remarks" + index}
                                                                                                disabled={!canEdit}
                                                                                                onChange={(value) => {
                                                                                                    line.additional_remarks = value.target.value
                                                                                                    values.line_ids[index].additional_remarks = value.target.value
                                                                                                }
                                                                                                }
                                                                                                value={values.line_ids[index].additional_remarks}
                                                                                            />

                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name={`line_ids[${index}].additional_remarks`} component="div" />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col> */}

                                                                            </Row >
                                                                        </>
                                                                    ))}

                                                                    <div className='py-2 d-flex justify-content-center col-12'>
                                                                        <button
                                                                            title="Add Additional Segment"
                                                                            type='submit'
                                                                            className='btn btn-dark rounded-0 btn-sm col-6 mr-1'
                                                                            onClick={() => {
                                                                                if (formRef?.current?.isValid) {
                                                                                    push({
                                                                                        departure_city: '',
                                                                                        arrival_city: '',
                                                                                        need_hotel: true,
                                                                                        need_visa: false,
                                                                                        purpose: '',
                                                                                        passport_visa_info: '',
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            <span className='indicator-label'>Additional Segment</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className='py-2 d-flex justify-content-between col-12'>
                                                                        {canEdit &&
                                                                            <button
                                                                                title="Submit for approval"
                                                                                type='submit'
                                                                                onClick={() => {
                                                                                    is_submitting_form = true
                                                                                    submit_validation_transaction = true
                                                                                }}
                                                                                className='btn btn-dark rounded-0 btn-sm col-3  mr-2'
                                                                                disabled={!canEdit}>
                                                                                <span className='indicator-label'>Submit</span>
                                                                            </button>
                                                                        }
                                                                        {canEdit &&
                                                                            <button
                                                                                title="Save Draft"
                                                                                type='submit'
                                                                                onClick={() => {
                                                                                    is_submitting_form = true
                                                                                    submit_validation_transaction = false
                                                                                }}
                                                                                className='btn btn-dark rounded-0 btn-sm col-3  mr-2'
                                                                                disabled={!canEdit}>
                                                                                <span className='indicator-label'>Save</span>
                                                                            </button>
                                                                        }
                                                                        {canEdit && id &&
                                                                            <button
                                                                                title="Delete"
                                                                                type='reset'
                                                                                onClick={() => {
                                                                                    deleteTransaction()
                                                                                }}
                                                                                className='btn btn-dark rounded-0 btn-sm col-3  mr-2'
                                                                                disabled={!canEdit}>
                                                                                <span className='indicator-label'>Delete</span>
                                                                            </button>
                                                                        }

                                                                        <button
                                                                            title="Discard"
                                                                            type='reset'
                                                                            onClick={() => cancel()}
                                                                            className='btn btn-dark rounded-0 btn-sm col-3 ml-1'
                                                                            data-kt-users-modal-action='cancel'
                                                                        >
                                                                            <span className='indicator-label'>Discard</span>

                                                                        </button>
                                                                    </div>

                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </Row>
                                                </fieldset>

                                                {/* begin::Actions */}
                                                <Row className="m-0 my-2">
                                                </Row>
                                                {/* end::Actions */}
                                            </Row>
                                        </>
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col >
            </Row >
        </>
    )
}

export default TransactionAPPform;
