import { SystemAttendanceLine } from "./model";
import { FC, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import { AZKHelper } from "app/modules/components/azkHelper";
import { CreateAttendanceAdjustment } from "./_requests";
import axios from "axios";
import { API_URL } from "_azkatech/azkHelper";

type Props = {
    line: SystemAttendanceLine,
    canReverse: boolean,
    closeModal: () => {}
}

const TransactionSystemAPPViewform: FC<Props> = ({ line, canReverse, closeModal }) => {
    const Swal = require('sweetalert2')

    useEffect(() => {
        document.body.classList.remove('page-loading')
    }, [])

    const reverseRecord = () => {
        document.body.classList.add('page-loading')
         axios.get(`${API_URL}/hr.employee?filters=[('azadea_id','=',${line.azadeaId})]&include_fields=['id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', ('grade_id',('id','grade_name')), ('branch_id',('id','name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')),('employment_category_id',('id','name', 'code')), 'azadea_id']`).then(async response => {
            if (response && response?.data?.results && response.data.results[0]) {
                const values = {
                    id: undefined,
                    employee_id: response.data.results[0].id,
                    az_country_id: undefined,
                    az_company_id: undefined,
                    branch_id: undefined,
                    brand_id: undefined,
                    grade_id: undefined,
                    section_id: undefined,
                    job_id: undefined,
                    time_set: undefined,
                    azadea_employee_name: undefined,
                    adjustment_reason_id: undefined,
                    is_reverse: true,
                    line_ids: [
                        {
                            date: moment(line.attendanceDate).format(AZKHelper.BACKEND_DATE_FORMAT), hour: line.attendanceHour, min: line.attendanceMinute, adjustment_type: line.attendanceType === 'I' ? 'out' : 'in',
                            id: 0
                        }
                    ]
                }
                CreateAttendanceAdjustment(values).then(data => {
                    closeModal()
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "center",
                        // title: 'Access Error!',
                        text: 'Your transaction had been saved!',
                        icon: "success",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        // toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            }
        })

    }

    return (
        <>{line && <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
                style={{ zIndex: 99999 }}
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered px-3'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-3 px-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>Details</h4>
                            {/* end::Modal title */}
                        </div>
                        {/* <div className='text-center py-3'>
                            <h4 className='m-0'>Leave Details</h4>
                        </div> */}
                        {/* begin::Modal body */}
                        <div className='' >
                            <Row className="m-0 py-3">
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Id/Name
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line?.azadeaId} / {line?.employeeName}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Date
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {moment(line?.attendanceDate?.replace('T', ' ')).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Type
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line.attendanceType === 'I' ? 'In' : line.attendanceType === 'O' ? 'Out' : ''}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Hour
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line?.attendanceHour}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Mins
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line?.attendanceMinute}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>

                            </Row>
                        </div>
                        {/* end::Modal body */}
                        <div className='my-2 row m-0'>
                            {canReverse && 
                            <div className={`col-12 d-flex justify-content-between py-3`}>
                            <button
                                    title="Submit for Reverse this entry"
                                    type='button'
                                    onClick={() => reverseRecord()}
                                    className='btn btn-dark rounded-0 btn-sm col-4'
                                >
                                    <span className='indicator-label'>Reverse</span>
                                </button>
                                <button id='discard-button' type='button' className='btn btn-dark rounded-0 btn-sm col-4'
                                    onClick={() => closeModal()}>
                                    <span className='indicator-label'>Discard</span>
                                </button>
                            </div> }
                            {!canReverse && 
                            <div className={`col-12 d-flex justify-content-end py-3`}>
                                <button id='discard-button' type='button' className='btn btn-dark rounded-0 btn-sm col-4'
                                    onClick={() => closeModal()}>
                                    <span className='indicator-label'>Discard</span>
                                </button>
                            </div> }
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>}
        </>
    )
}

export default TransactionSystemAPPViewform;
