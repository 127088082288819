import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { LayoutSetup, PageDataProvider } from './core'
import { ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../assets/ts/components'


let class_values = ''
const MasterLayout = () => {
  const location = useLocation();
  const [appView, setAppView] = useState<boolean>(false);

  useEffect(() => {
    const app_view = location.pathname.includes('/app/')
    setAppView(app_view)
    if (app_view){
      console.log('---------------------------------' + app_view)
      class_values = 'app_view_mode'
    }
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key, appView])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        {/* begin::Page */}
        <div className='page d-flex flex-row flex-column-fluid'>
          {/* begin::Wrapper */}
          <div className={`d-flex flex-column flex-row-fluid ${class_values} ${appView ? 'full-width-container' : 'wrapper'}`} id='kt_wrapper'>
            {!appView &&
              <>
                <HeaderWrapper />

                <Toolbar />
              </>}

            {/* begin::Content */}
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* end::Content */}
            <Footer />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {!appView &&
        <RightToolbar />
      }
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      {!appView && <ScrollTop />
      }
    </PageDataProvider>
  )
}

export { MasterLayout }

