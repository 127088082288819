import { useState, useEffect } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import { useNavigate, useParams } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { Col, Form, Row } from 'react-bootstrap';
import { AuthModel } from 'app/modules/auth';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';
import { AttendanceAdjustment, SystemAttendanceLine } from './model';
import TransactionAPPViewform from './app-view';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import AzkSelect from '_azkatech/azkSelect';
import { AsyncPaginate } from 'react-select-async-paginate';
import TransactionSystemAPPViewform from './system-app-view';
import { ErrorMessage } from 'formik';

const TransactionAppListView = () => {
    const { id } = useParams();
    const [employeeId, setEmployeeId] = useState<number>()
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.attendance.adjustment"

    const [selectedAttendanceAdjustment, setSelectedAttendanceAdjustment] = useState<AttendanceAdjustment>();
    const [selectedsystemLine, setSelectedsystemLine] = useState<SystemAttendanceLine>();
    const [currectEmployee, setCurrectEmployee] = useState<number>();
    const [currectEmployeeAzadeaId, setCurrectEmployeeAzadeaId] = useState<number>();

    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();

    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-adjustment-list-filter-hr-lite'
    const fields = "'id', 'name', 'employee_id', 'azadea_employee_name', 'create_date', 'can_review', ('adjustment_reason_id', ('id', 'name')), 'employee_name', ('az_country_id',('name')), ('az_company_id',('name')), ('brand_id',('name')), ('job_id',('name')), 'x_state', 'state', 'x_state_view', 'restarted'"

    const [shopUser, setShopUser] = useState<boolean>(true);
    const [myAttendance, setMyAttendance] = useState<boolean>(true);
    const [requestView, setRequestView] = useState<boolean>(false);
    const [isManger, setIsManger] = useState<boolean>(false);

    const updateListDataDomain = (currectEmployee_id, employee_id, adjustment_type, state, myRequest, dateFrom, dateTo, isManger, shopUSer) => {
        let filter = {}
        let conds: string[] = [];
        if (currectEmployee_id !== '' && currectEmployee_id && currectEmployee_id != 'undefined') {
            conds.push(`('employee_id','=', ${currectEmployee_id})`)
            if (handleChangeSelection) filter['employee_id'] = currectEmployee_id
        }
        if (adjustment_type !== '' && adjustment_type && adjustment_type != 'undefined') {
            conds.push(`('adjustment_reason_id','=', ${adjustment_type})`)
            if (handleChangeSelection) filter['adjustment_reason_id'] = adjustment_type
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        if (employee_id !== '' && employee_id && employee_id != 'undefined') {
            conds.push(`('employee_id','=', ${employee_id})`)
            if (handleChangeSelection) filter['employee_id'] = employee_id
        }
        if (dateFrom && moment(dateFrom).isValid()) {
            conds.push(`('create_date','>=', '${moment(dateFrom).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
            if (handleChangeSelection) filter['date_from'] = dateFrom
        }
        if (dateTo && moment(dateTo).isValid()) {
            conds.push(`('create_date','<=', '${moment(dateTo).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
            if (handleChangeSelection) filter['date_to'] = dateTo
        }
        if (!isManger || myRequest) {
            conds.push(`('employee_id','=', ${currectEmployee_id})`)
        } else {
            if (isManger && shopUSer) {
                conds.push(`'|', ('employee_id.parent_id','=', ${currectEmployee_id}), ('employee_id.super_parent_id','=', ${currectEmployee_id})`)
            }
            if (isManger && !shopUSer) {
                conds.push(`('employee_id.parent_id','=', ${currectEmployee_id})`)
            }
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        return conds.join(',');
    }

    const [data, setData] = useState<AttendanceAdjustment[]>();
    const [systemData, setSystemData] = useState<SystemAttendanceLine[]>();
    const [pennding, setPending] = useState<boolean>(false);
    const [missingOneTypeView, setMissingOneTypeView] = useState<boolean>(false);

    const viewResult = async (dataDomain) => {
        document.body.classList.add('page-loading')
        setPending(true);
        let remoteData = []
        try {
            const result = await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${dataDomain}]&include_fields=[${fields}]&order=name DESC`); //&offset=${offset}&limit=${perPage}
            remoteData = result.data?.results ? result.data.results : []
            document.body.classList.remove('page-loading')
        } catch (error) {
            console.error(error);
            document.body.classList.remove('page-loading')
        }
        if (remoteData)
            setData(remoteData);
        setSystemData([]);
        setPending(false);
    }

    const viewSystemResult = async (checkMissingOneType, download, employee_id, azadea_id) => {
        if (myAttendance){
            azadea_id = currectEmployeeAzadeaId
        }
        setPending(true);
        if (!azadea_id && !myAttendance && !requestView && checkMissingOneType){
            return;
        }
        let remoteData = []
        document.body.classList.add('page-loading')
        try {
            const date_from = moment(dateFrom).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
            let date_to = moment().utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
            if (moment(dateTo).isValid()){
                date_to = moment(dateTo).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
            }
            if (checkMissingOneType) {
                const result = await axios.put(`${API_URL}/hr.employee/${employee_id}/search_attendence_system_records_missing_one_type_report?fromDate=${date_from}&toDate=${date_to}&azadeaId=${azadea_id}`, { 'fromDate': date_from, 'toDate': date_to, 'azadeaId': azadea_id })
                remoteData = result?.data ? result?.data : []
                setMissingOneTypeView(true)
            } else {
                setMissingOneTypeView(false)
                let az_adjustment_type_value = undefined
                if (az_adjustment_type){
                    az_adjustment_type_value = az_adjustment_type['value'] 
                }
                const result = await axios.put(`${API_URL}/hr.employee/${employee_id}/search_attendence_system_records?fromDate=${date_from}&toDate=${date_to}&azadeaId=${azadea_id}&type=${az_adjustment_type}`, { 'fromDate': date_from, 'toDate': date_to, 'azadeaId': azadea_id, 'type': az_adjustment_type_value, 'download': download})
                if (download) {
                    const file_name = result.data['file_name']
                    const blob = new Blob([result.data['content']], { type: 'text/csv' })
                    // const url = window.URL.createObjectURL(blob)
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.setAttribute('download', file_name)
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // window.URL.revokeObjectURL(url)
                    // window?.postMessage(JSON.stringify({ blob }));

                    // Convert Blob to Base64
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        const base64Data = reader.result;
                        // Create and trigger the download
                        // Post the message with Base64 content
                        // window?.postMessage(JSON.stringify({ base64Data }));
                        const link = document.createElement('a');
                        if (base64Data){
                        link.href = base64Data?.toString();
                        link.setAttribute('Attendance download', file_name);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        }
                    };
                } else {
                    remoteData = result?.data ? result.data : []
                }
            }
            document.body.classList.remove('page-loading')
        } catch (error) {
            console.error(error);
            document.body.classList.remove('page-loading')
        }
        if (remoteData)
            setSystemData(remoteData);
        setData([]);
        setPending(false);
    }
    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        const from_date = moment().startOf('month').format(AZKHelper.BACKEND_DATE_FORMAT)
        const to_date = moment().format(AZKHelper.BACKEND_DATE_FORMAT)
        setDateFrom(from_date)
        setDateTo(to_date)
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            if (response && response.is_manager_fr && response.is_manager_fr !== null) {
                setIsManger(true)
                setCurrectEmployee(response.id)
            } else {
                setIsManger(false)
                setCurrectEmployee(response.id)
            }
            setCurrectEmployeeAzadeaId(response.azadea_id)
            setEmployeeId(response.azadea_id)
            if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
            // if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
            const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
            if (filterVals) {
                const jsonObj = JSON.parse(filterVals)
                // setShowFilters(jsonObj['showFilters'])
                // if (jsonObj['employee_id']) await setEmployeeId(jsonObj['employee_id'])
                if (jsonObj['az_attendance_adjustment_type']) await setAttendanceAdjustmentType(jsonObj['az_attendance_adjustment_type'])
                if (jsonObj['az_adjustment_reason']) await setAttendanceAdjustmentReason(jsonObj['az_adjustment_reason'])
                if (jsonObj['date_from']) await setDateFrom(jsonObj['date_from'])
                if (jsonObj['date_to']) await setDateTo(jsonObj['date_to'])
                // if (jsonObj['x_state']) await setState(jsonObj['x_state'])
                setTimeout(() => {
                    if (requestView) viewResult(updateListDataDomain(response.id, undefined, jsonObj['az_attendance_adjustment_type'].value, jsonObj['x_state'], myAttendance, dateFrom, dateTo, response.is_manager_fr, response.is_back_office_fr));
                    // else viewSystemResult(false, false, response.id, response.azadea_id);
                }, 500)
            } else {
                setTimeout(() => {
                    if (requestView) viewResult(updateListDataDomain(response.id, undefined, undefined, undefined, undefined, from_date, to_date, response.is_manager_fr, response.is_back_office_fr));
                    // else viewSystemResult(false, false, response.id, response.azadea_id);
                }, 500)
            }
            setTimeout(() => {
                setHandleChangeSelection(true)
            }, 1000)
        })
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
        if(id){
            let transaction: AttendanceAdjustment = {line_ids:[]}
            transaction['id'] = parseInt(id)
            setSelectedAttendanceAdjustment(transaction)
        }
    }, []);

    // #region status Dropdown
    const [az_state, setState] = useState('');

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: `('basic_status', '=', 'true')`,
        titleField: "name",
        allowEmpty: true,
        isSearchable: false,
        defaultValue: az_state,
        onChange: (value) => {
            setState(value)
        },
    });
    // #endregion status Dropdown

    // #region Type Dropdown

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'in', label: 'In' })
        options.push({ value: 'out', label: 'Out' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    const [az_adjustment_type, setAttendanceAdjustmentType] = useState<any>('');

    // #endregion Type Dropdown

    // #region status Dropdown
    const [az_adjustment_reason, setAttendanceAdjustmentReason] = useState<any>('');

    const az_adjustment_reason_select = azkSelect({
        compID: "az_attendance_adjust_reason_select",
        modelName: "az.attendance.adjust.reason",
        valueField: "id",
        dataDomain: '',
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        onChange: (value) => {
            if (value) setAttendanceAdjustmentReason(value)
            else setAttendanceAdjustmentReason('')
        },
        defaultValue: az_adjustment_reason,
    });
    // #endregion Type Dropdown

    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(true);

    return (
        <>
            <Row className="m-0 p-0">

                <KTCard className='rounded-0 p-0 custom-input-container'>
                    <Row className="m-0 p-0">
                        {isManger && <>
                            <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-0 m-0">
                                <button disabled={false}
                                    className={`btn btn-light px-2 w-50 rounded-0 ${myAttendance ? "active-custom-tab-btn" : "custom-tab-btn"}`}
                                    type='reset'
                                    onClick={() => {
                                        setMyAttendance(true);
                                        if (requestView) {
                                            viewResult(updateListDataDomain(currectEmployee, undefined, az_adjustment_reason, az_state, true, dateFrom, dateTo, isManger, shopUser))
                                        } else {
                                            setSystemData([]);
                                        }
                                    }
                                    }
                                >My Attendance</button>
                                <button disabled={false}
                                    className={`btn btn-light px-2 w-50 rounded-0 ${myAttendance ? "custom-tab-btn" : "active-custom-tab-btn"}`}
                                    type='reset'
                                    onClick={() => {
                                        setMyAttendance(false);
                                        if (requestView) {
                                            viewResult(updateListDataDomain(currectEmployee, undefined, az_adjustment_reason, az_state, false, dateFrom, dateTo, isManger, shopUser))
                                        } else {
                                            setSystemData([]);
                                        }
                                    }}
                                >Team Attendance</button>
                            </Col>
                        </>}
                    </Row>
                </KTCard>
                <KTCard className='rounded-0 custom-input-container'>
                    <div className='card-body p-2'>
                        <div className='row m-0'>
                            <h6 className='py-2'>
                                View, edit, and add to keep track of your attendance effortlessly.
                            </h6>
                            
                            {!requestView &&
                                <div className='fv-row col-lg-12 col-md-12 col-12'>
                                    <h6 className='px-2'>
                                        <small>The system will only display the top 50 records. To view all data, please click the "Download" button</small>
                                    </h6>
                                </div>
                            }
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                {/* begin::Label */}
                                <label className='form-label required'>From</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateFrom(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    }}
                                    defaultValue={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    value={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    max={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6' >
                                {/* begin::Label */}
                                <label className='form-label required'>To</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateTo(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    }}
                                    defaultValue={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    value={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    max={moment().format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            {requestView && <>
                                    <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                        {/* begin::Label */}
                                        <label className='form-label'>Status</label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        {handleChangeSelection && (<>
                                            {az_state_select}
                                        </>)}
                                        {/* end::Input */}
                                    </div>
                                    <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                        {/* begin::Label */}
                                        <label className='form-label'>Reason</label>
                                        {/* end::Label */}
                                        {/* begin::Input */}
                                        {handleChangeSelection && (<>
                                            {az_adjustment_reason_select}
                                        </>)}
                                        {/* end::Input */}
                                    </div>
                                </>
                            }
                            {!requestView && requestView &&
                                <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                    {/* begin::Label */}
                                    <label className='form-label'>Type</label>
                                    {/* end::Label */}
                                    {/* begin::Input */}
                                    {handleChangeSelection && (<>
                                        <AsyncPaginate className='rounded-0 col-12'
                                            placeholder="Select an option"
                                            loadOptions={loadTypeOptions}
                                            onChange={(value) => {
                                                setAttendanceAdjustmentType(value)
                                            }}
                                            isClearable={true}
                                            defaultValue={az_adjustment_type}
                                            value={az_adjustment_type}
                                            id={`adjustment_type`} name={`adjustment_type`}
                                        />
                                    </>)}
                                    {/* end::Input */}
                                </div>
                            }
                            {!myAttendance && <>
                                <div className='fv-row mb-2 col-lg-12 col-md-12 col-12'>
                                    {/* begin::Label */}
                                    <label className='form-label'>Azadea ID</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    {handleChangeSelection && (<>
                                        <AzkSelect
                                            compID="azk_temp"
                                            modelName="hr.employee"
                                            valueField="ex_az_id"
                                            titleField="azadea_employee_name"
                                            dataDomain={`('parent_id','=', ${currectEmployee})`}
                                            allowEmpty={true}
                                            fieldName='employee_id'
                                            onChange={(value) => {
                                                setEmployeeId(parseInt(value))
                                            }}
                                            searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                    </>)}
                                    {/* end::Input */}
                                    {/* <div className='text-danger'>
                                        <ErrorMessage name='payslip_month' />
                                    </div> */}
                                    {!employeeId && !requestView && pennding && missingOneTypeView &&
                                     <div className='text-danger'>
                                        Azadea Id is required Field
                                    </div>
                                    }
                                </div>
                            </>}
                            <div className='fv-row m-0 d-flex justify-content-between p-0 m-0'>
                                {requestView &&
                                    <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => {
                                        viewResult(updateListDataDomain(currectEmployee, employeeId, az_adjustment_reason, az_state, myAttendance, dateFrom, dateTo, isManger, shopUser))
                                    }}>
                                        View Result
                                    </button>
                                }

                                {!requestView &&
                                    <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => {
                                        viewSystemResult(false, false, currectEmployee, employeeId)
                                    }}>
                                        View Result
                                    </button>
                                }
                                {!requestView &&
                                    <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => {
                                        viewSystemResult(true, false, currectEmployee, employeeId)
                                    }}>
                                        Missing one type
                                    </button>
                                }
                                {!requestView &&
                                    <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => {
                                        viewSystemResult(false, true, currectEmployee, employeeId)
                                    }}>
                                        Download
                                    </button>
                                }
                            </div>

                        </div>
                    </div>
                </KTCard>
                <hr className="hr m-0" />
                {/* <KTCard className={`rounded-0 py-2 px-0 m-0 custom-KTCard-table ${myAttendanceAdjustments? '':'custom-KTCard-table-with-check'}`} > */}
                {myAttendance &&
                <KTCard className={`rounded-0 py-2 px-2 m-0 custom-KTCard-table }`} >
                    <div className='row m-0 d-flex justify-content-end p-0 m-0'>
                        <div className='d-flex justify-content-end p-0 m-0'>
                            <button type='button' className='btn btn-outline btn-color-dark btn-sm rounded-0' onClick={() => navigate(`/app/self-service/attendance/new`, { state: {}, replace: true })}>
                                New
                                <KTIcon iconName='plus' className='fs-2' />
                            </button>
                        </div>
                    </div>
                </KTCard>
                }
            </Row>
            <Row className='m-0 px-0'>
                <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-0 m-0">
                <button disabled={false}
                        className={`btn btn-light px-2 w-50 rounded-0 ${requestView ? "custom-tab-btn" : "active-custom-tab-btn"}`}
                        type='reset'
                        onClick={() => {
                            setRequestView(false);
                            viewSystemResult(false, false, currectEmployee, employeeId)
                        }}
                    >Details</button>
                    <button disabled={false}
                        className={`btn btn-light px-2 w-50 rounded-0 ${requestView ? "active-custom-tab-btn" : "custom-tab-btn"}`}
                        type='reset'
                        onClick={() => {
                            setRequestView(true);
                            viewResult(updateListDataDomain(currectEmployee, undefined, az_adjustment_reason, az_state, true, dateFrom, dateTo, isManger, shopUser))
                        }
                        }
                    >Requests</button>
                </Col>
            </Row>
            <Row className='m-0 px-3 py-2' style={{ backgroundColor: '#D9D9D9', color: '#838383' }}>
                <Col lg="7" sm="6" className="adjustment-header-label col-6">Details</Col>
                <Col lg="4" sm="4" className="adjustment-header-label col-4">Status</Col>
                <Col lg="1" sm="2" className="adjustment-header-label col-2"></Col>
            </Row>
            {requestView && data && data.length > 0 &&
                data.map(adjustment => {
                    return (<>
                        <Row className='m-0 px-3 py-2'>
                            {myAttendance && <>
                                <Col lg="7" sm="6" className="col-6"><span className='adjustment-form-label-value-bold '><b>{adjustment.adjustment_reason_id.name}</b> </span><br />
                                    <span className='adjustment-form-label-value '>Date: {moment(adjustment.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</span></Col>
                                <Col lg="4" sm="4" className="adjustment-form-label-value col-4">{adjustment.x_state_view}</Col>
                            </>}
                            {!myAttendance && <>
                                <Col lg="7" sm="6" className="col-6"><span className='adjustment-form-label-value-bold '><b>{adjustment.azadea_employee_name}</b> </span><br />
                                    <span className='adjustment-form-label-value '>{adjustment.adjustment_reason_id.name}</span></Col>
                                <Col lg="4" sm="4" className="adjustment-form-label-value col-4">{adjustment.x_state_view}</Col>
                            </>}
                            <Col lg="1" sm="2" className='col-2'>
                                <button title='view-details' name='view-details' className='btn btn-outline btn-color-dark btn-sm border-0' onClick={() => setSelectedAttendanceAdjustment(adjustment)}>
                                    <i className="bi bi-arrow-right fs-1 fw-bolder"></i>
                                </button>
                            </Col>
                        </Row>
                        <hr className="hr m-0" />
                    </>)
                })
            }
            {!requestView && systemData && systemData.length > 0 &&
                systemData.map(line => {
                    return (<>
                        <Row className='m-0 px-3 py-2'>
                            {myAttendance && <>
                                <Col lg="7" sm="6" className="col-6"><span className='adjustment-form-label-value-bold '>
                                    <b>Date: {moment(line.attendanceDate).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</b></span></Col>
                                <Col lg="4" sm="4" className="adjustment-form-label-value col-4">
                                    <b>{line.attendanceType === 'I' ? 'In' : line.attendanceType === 'O' ? 'Out' : ''}</b>&nbsp;&nbsp;
                                    {line.attendanceHour?.padStart(2, '0')}:{line.attendanceMinute?.padStart(2, '0')}<br/>
                                    {line.message && <span className='adjustment-form-label-value '>{line.message}</span>}</Col>
                            </>}
                            {!myAttendance && <>
                                <Col lg="7" sm="6" className="col-6"><span className='adjustment-form-label-value-bold '><b>{line.employeeName}</b> </span><br />
                                    <span className='adjustment-form-label-value '>Date: {moment(line.attendanceDate).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</span>
                                    </Col>
                                <Col lg="4" sm="4" className="adjustment-form-label-value col-4">

                                    <b>{line.attendanceType === 'I' ? 'In' : 'Out'}</b>&nbsp;&nbsp;
                                    {line.attendanceHour?.padStart(2, '0')}:{line?.attendanceMinute?.padStart(2, '0')}<br/>
                                    {line.message && <span className='adjustment-form-label-value '>{line.message}</span>}</Col>
                            </>}
                            <Col lg="1" sm="2" className='col-2'>
                                <button title='view-details' name='view-details' className='btn btn-outline btn-color-dark btn-sm border-0' onClick={() => {
                                    console.log('-------------------missingOneTypeView-----' , missingOneTypeView);
                                    console.log('--------------shopUser----------' , shopUser);
                                    console.log('-----------------azadeaId-------' , line.azadeaId);
                                    console.log('------------------employeeId------' , employeeId);
                                    console.log('------------------employeeId +  === line.azadeaId ------' , (employeeId + '' === line.azadeaId && !shopUser));

                                    setSelectedsystemLine(line)
                                }}>
                                    <i className="bi bi-arrow-right fs-1 fw-bolder"></i>
                                </button>
                            </Col>
                        </Row>
                        <hr className="hr m-0" />
                    </>)
                })
            }
            <div>
                {selectedAttendanceAdjustment && requestView && <TransactionAPPViewform adjustmentId={selectedAttendanceAdjustment.id} closeModal={function (): {} {
                    setSelectedAttendanceAdjustment(undefined);
                    viewResult(updateListDataDomain(currectEmployee, employeeId, az_adjustment_reason, az_state, myAttendance, dateFrom, dateTo, isManger, shopUser));
                    return false;
                }}></TransactionAPPViewform>}
                {selectedsystemLine && !requestView && <TransactionSystemAPPViewform line={selectedsystemLine} canReverse={((currectEmployeeAzadeaId + '' === selectedsystemLine.azadeaId && !shopUser) || shopUser) && missingOneTypeView} closeModal={function (): {} {
                    setSelectedsystemLine(undefined);
                    // viewSystemResult(false, false);
                    return false;
                }}></TransactionSystemAPPViewform>}
            </div>
        </>)
}

export default TransactionAppListView;